<script lang="ts">
    import { onMount } from 'svelte';
    import Dropzone from 'dropzone';
    import { writable } from 'svelte/store';
    import { Button } from '$lib/components/ui/button/index.js';
    import * as Dialog from '$lib/components/ui/dialog/index.js';
    import { Input } from '$lib/components/ui/input';
    import { Label } from '$lib/components/ui/label/index.js';
    import { Tabs, TabsList, TabsTrigger } from '$lib/components/ui/tabs';
    import { Switch } from '$lib/components/ui/switch';
    import axios from 'axios';
    import { Textarea } from '$lib/components/ui/textarea/index.js';
    import toast from 'svelte-french-toast';
    import { Inertia } from '@inertiajs/inertia';
    import { page } from '@inertiajs/svelte';
    import QuestionMark from './QuestionMark.svelte';

    let postText = persistentWritable<string>('createPost_text', '', 300000);
    let uploadedFiles = persistentWritable<
        { attachmentID: string; path: string; type: string; thumbnail: string; loading: boolean; progress: number }[]
    >('createPost_files', [], 300000);
    let dropzoneElement: HTMLElement;
    let myDropzone: Dropzone;
    let selectedImageUrl = writable('');
    let showDropdown = writable(false);
    const UPLOAD_URL = '/attachment/upload/post';
    const SAVE_POST_URL = '/posts/save';
    let price = writable<number | null>(null);
    let isEditMode = false;
    let postId: any = '';
    let isLimitedEdition = writable(false);
    let selectedTab = writable('Nobody');
    let isModalOpen = writable(false);
    let isPublishDialogOpen = writable(false);
    let modalSupplyLimit: number | string = '';
    let postTitle = writable('');
    let modalPrice: number | null = null;
    let modalIsLimitedEdition = false;
    let modalSelectedTab = 'Nobody';
    const conversionRate = 2.4;
    $: dollarPrice = modalPrice !== null ? (modalPrice / conversionRate).toFixed(2) : '0.00';
    function openModal() {
        modalPrice = $price;
        modalIsLimitedEdition = $isLimitedEdition;
        modalSelectedTab = $selectedTab;
        isModalOpen.set(true);
    }

    function persistentWritable<T>(key: string, initialValue: T, ttl: number) {
        const now = Date.now();
        const storedValue = localStorage.getItem(key);
        let data = initialValue;

        if (storedValue) {
            const parsed = JSON.parse(storedValue);
            if (now < parsed.expiry) {
                data = parsed.value;
            } else {
                localStorage.removeItem(key);
            }
        }

        const store = writable<T>(data);

        store.subscribe((value) => {
            const expiry = Date.now() + ttl;
            localStorage.setItem(key, JSON.stringify({ value, expiry }));
        });

        return store;
    }

    let postData;
    $: postData = $page.props?.postData;

    onMount(() => {
        (async () => {
            const path = window.location.pathname;
            const editMatch = path.match(/\/posts\/edit\/(\d+)/);

            if (editMatch) {
                isEditMode = true;
                postId = editMatch[1];
                if (isEditMode) {
                    postText.set(postData?.text || '');
                    uploadedFiles.set(
                        postData?.attachments.map((attachment: any) => ({
                            attachmentID: attachment.id,
                            path: attachment.path,
                            type: attachment.type,
                            thumbnail: attachment.thumbnail,
                            loading: false,
                            progress: 100,
                        }))
                    );
                }
            }

            Dropzone.autoDiscover = false;

            myDropzone = new Dropzone(dropzoneElement, {
                url: '/upload',
                maxFilesize: 10,
                acceptedFiles:
                    'image/png, image/jpeg, image/jpg, image/gif, audio/wav, audio/mp3, audio/ogg, video/mp4, video/avi, video/mov, video/moov, video/m4v, video/mpeg, video/wmv, video/asf',
                addRemoveLinks: false,
                autoProcessQueue: false,
                previewsContainer: false,
                clickable: true,
                dictDefaultMessage: `
            <div class="flex items-center justify-center gap-3 ">
                <ion-icon name="add-outline" class="text-3xl"></ion-icon>
                <p class="mt-3 font-semibold text-[18px] font-sans">Click Here or Drag-And-Drop to Add Media</p>
            </div>
        `,
            });

            myDropzone.on('addedfile', async (file) => {
                const tempFile = {
                    attachmentID: null,
                    path: null,
                    type: null,
                    thumbnail: null,
                    loading: true,
                    progress: 0,
                };
                uploadedFiles.update((files: any) => [...files, tempFile]);

                try {
                    const formData = new FormData();
                    formData.append('file', file);

                    const response = await axios.post(UPLOAD_URL, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        onUploadProgress: (progressEvent: any) => {
                            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            uploadedFiles.update((files) => {
                                return files.map((f) => (f.loading ? { ...f, progress } : f));
                            });
                        },
                    });

                    const fileData = {
                        attachmentID: response.data.attachmentID,
                        path: response.data.path,
                        type: response.data.type,
                        thumbnail: response.data.thumbnail,
                        loading: false,
                        progress: 100,
                    };

                    uploadedFiles.update((files) => {
                        return files.map((f) => (f.loading ? fileData : f));
                    });
                } catch (error) {
                    console.error('Error uploading file:', error);
                }
            });

            myDropzone.on('removedfile', (file) => {
                uploadedFiles.update((files) => files.filter((f) => f.thumbnail !== URL.createObjectURL(file)));
            });
        })();

        return () => {
            myDropzone?.destroy();
        };
    });

    async function publishPost() {
        try {
            const postContent = $postText.trim();
            const uploadedFileMetadata = $uploadedFiles;
            const formData = new FormData();

            uploadedFileMetadata.forEach((file: any, index) => {
                formData.append(`attachments[${index}][attachmentID]`, file.attachmentID);
                formData.append(`attachments[${index}][path]`, file.path);
                formData.append(`attachments[${index}][type]`, file.type);
                formData.append(`attachments[${index}][thumbnail]`, file.thumbnail);
            });

            let safePrice = modalPrice !== null ? modalPrice.toString() : '0';
            let safeSupplyLimit = modalSupplyLimit;
            let safePostTitle = $postTitle.trim() !== '' ? $postTitle.trim() : 'Untitled Post';

            let enablePublicDownload = 'false';
            let enableOwnerDownload = 'false';

            if ($selectedTab === 'Everybody') {
                enablePublicDownload = 'true';
            } else if ($selectedTab === 'Owners') {
                enableOwnerDownload = 'true';
            }

            



            formData.append('text', postContent);

            formData.append('price', modalPrice !== null ? modalPrice.toString() : '0');
            formData.append('enable_public_download', `${enablePublicDownload}`);
            formData.append('enable_owner_download', `${enableOwnerDownload}`);
            formData.append('supply_limit', `${safeSupplyLimit.toString() || ''}`);

            formData.append('postNotifications', 'false');
            formData.append('postReleaseDate', '');
            formData.append('postExpireDate', '');
            formData.append('postTitle', `${safePostTitle}`);
            formData.append('is_limited_edition_post', `${modalIsLimitedEdition}`);
            formData.append('type', isEditMode ? 'update' : 'create');
            if (isEditMode) {
                formData.append('id', postId);
            }


            const response = await axios.post(SAVE_POST_URL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                const message = isEditMode ? 'Post updated successfully!' : 'Post published successfully!';
                toast.success(message, {
                    position: 'top-right',
                    duration: 3000,
                });
                postText.set('');
                uploadedFiles.set([]);
                const username = $page.props?.user?.username;
                if (username) {
                    Inertia.visit(`/${username}`);
                } else {
                    toast.error('Username not found in the response.', {
                        position: 'top-right',
                        duration: 3000,
                    });
                    console.error('User data missing:', response.data);
                }
            } else {
                console.error('API Error:', response?.data?.errors);
            }
        } catch (error: any) {
            toast.error('Error publishing post:', error);

            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message, {
                    position: 'top-right',
                    duration: 3000,
                });
            } else {
                toast.error('An error occurred while publishing the post.', {
                    position: 'top-right',
                    duration: 3000,
                });
            }
        }
    }

    function toggleDropdown() {
        showDropdown.update((state) => !state);
    }

    async function removeImage(attachment: { attachmentID: string; path: string; type: string; thumbnail: string }) {
        try {
            const payload = {
                attachmentId: attachment.attachmentID,
            };

            const response = await axios.post('/attachment/remove', payload);

            if (response.data.success) {
                uploadedFiles.update((files) => files.filter((file) => file.attachmentID !== attachment.attachmentID));
                toast.success('Attachment removed successfully.', {
                    position: 'top-right',
                    duration: 3000,
                });
            } else {
                console.error('API Errors:', response.data.errors);
                throw new Error(response.data.errors.join(', '));
            }
        } catch (error) {
            console.error('Error removing attachment:', error);
            toast.error('Failed to remove the attachment.', {
                position: 'top-right',
                duration: 3000,
            });
        }
    }
    function openImageDialog(imageUrl: string) {
        selectedImageUrl.set(imageUrl);
    }
    function saveChanges() {
        price.set(modalPrice);
        isLimitedEdition.set(modalIsLimitedEdition);
        selectedTab.set(modalSelectedTab);
        isModalOpen.set(false);
    }
    function handlePublishConfirm() {
        publishPost();
        isPublishDialogOpen.set(false);
    }

    function handlePublishCancel() {
        isPublishDialogOpen.set(false);
    }
</script>

<svelte:head>
    <link rel="stylesheet" href="https://unpkg.com/dropzone@5/dist/min/dropzone.min.css" type="text/css" />
</svelte:head>

<div class="mx-auto h-full w-full p-4 font-sans shadow-md md:p-8">
    <div class="mb-6 w-[100%]">
        <h2 class="text-[1.25rem] font-semibold">New Post</h2>

        {#if $uploadedFiles.length > 0}
            <div class="mt-4 flex w-full flex-wrap gap-4">
                {#each $uploadedFiles as file}
                    <div class="group relative h-40 w-40 shadow-md">
                        {#if file.loading}
                            <div class="flex h-full w-full items-center justify-center text-gray-500">
                                <div class="h-4 w-32 rounded-full bg-gray-200 dark:bg-gray-700">
                                    <div
                                        class="h-4 rounded-full bg-blue-600 transition-all duration-500 ease-in-out"
                                        style="width: {file.progress}%"
                                    ></div>
                                </div>
                            </div>
                        {:else if file.thumbnail}
                            <img
                                src="{file.thumbnail}"
                                alt="Uploaded file"
                                class="h-full w-full rounded object-cover"
                            />
                        {:else}
                            <div class="flex h-full w-full items-center justify-center text-gray-500">
                                Image failed to load
                            </div>
                        {/if}
                        <!-- Dropdown and Options -->
                        <div class="absolute inset-0 hidden items-start justify-between p-2 group-hover:flex">
                            <Button
                                type="button"
                                class="flex h-[24px] w-[24px] cursor-pointer items-center justify-center bg-[#393939] p-1 shadow-md"
                                title="Options"
                                aria-label="Options"
                                variant="secondary"
                                on:click="{toggleDropdown}"
                                on:mouseleave="{() => showDropdown.set(false)}"
                            >
                                <ion-icon name="ellipsis-horizontal-outline" class="text-l"></ion-icon>
                            </Button>

                            {#if $showDropdown}
                                <div
                                    class="absolute flex w-40 flex-col gap-2 rounded-lg bg-[#393939] p-2 text-white shadow-md"
                                    style="top: 3rem; left: 0;"
                                >
                                    <span
                                        class="flex w-full cursor-pointer items-center gap-2 rounded-md px-1 py-2 text-sm transition hover:bg-black"
                                    >
                                        <ion-icon name="settings-outline" class="text-lg"></ion-icon>
                                        <span>Configure</span>
                                    </span>

                                    <span
                                        class="flex w-full cursor-pointer items-center gap-2 rounded-md px-1 py-2 text-sm transition hover:bg-red-600"
                                        on:click="{() => removeImage(file)}"
                                        on:keydown="{(event) =>
                                            (event.key === 'Enter' || event.key === ' ') && removeImage(file)}"
                                        role="button"
                                        tabindex="0"
                                    >
                                        <ion-icon name="trash-outline" class="text-lg"></ion-icon>
                                        <span>Delete</span>
                                    </span>
                                </div>
                            {/if}
                        </div>
                        <Dialog.Root>
                            <Dialog.Trigger>
                                <Button
                                    class="absolute right-2 top-2 hidden h-[24px] w-[24px] cursor-pointer items-center justify-center bg-[#393939] p-1 shadow-md group-hover:flex"
                                    title="Full View"
                                    variant="secondary"
                                    on:click="{() => openImageDialog(file.thumbnail)}"
                                >
                                    <ion-icon name="expand-outline" class="text-l"></ion-icon>
                                </Button>
                            </Dialog.Trigger>
                            <Dialog.Overlay class="fixed inset-0 bg-black/60 transition-opacity" />
                            <Dialog.Content class="h-auto w-[350px] !border-none p-0 !outline-none lg:w-[800px] ">
                                <img
                                    src="{$selectedImageUrl}"
                                    alt="Uploaded pic"
                                    class=" h-full w-full rounded-lg object-contain"
                                />
                            </Dialog.Content>
                        </Dialog.Root>
                    </div>
                {/each}
            </div>
        {/if}
    </div>

    <div
        bind:this="{dropzoneElement}"
        class="dropzone flex h-[177px] w-full items-center justify-center rounded-md border !border-dashed border-gray-500 bg-transparent p-6 font-semibold transition hover:border-gray-500"
    ></div>

    <Textarea
        bind:value="{$postText}"
        placeholder="Enter Post Text here."
        class="mt-4 h-[75px] min-h-0 w-full border-none !outline-none ring-0 focus:border-none focus:outline-none focus:ring-0"
        maxlength="{250}"
    />
    <div class="mt-6 flex flex-wrap items-center gap-6 rounded-lg">
        <Dialog.Root bind:open="{$isModalOpen}">
            <Dialog.Trigger>
                <Button
                    variant="secondary"
                    class="flex items-center gap-2 rounded bg-[#D9D9D90D] text-sm font-bold transition"
                    on:click="{openModal}"
                >
                    <ion-icon name="settings-outline" class="text-xl"></ion-icon>

                    Configure
                </Button>
            </Dialog.Trigger>
            <Dialog.Content class="overflow-auto font-sans sm:max-w-[425px]">
                <Dialog.Header>
                    <Dialog.Title>
                        <div class="flex gap-2">
                            <ion-icon class="text-3xl" name="settings-outline"></ion-icon>
                            <div class="text-lg font-semibold">Post Settings</div>
                        </div>
                    </Dialog.Title>
                </Dialog.Header>
                <div class="grid gap-4 py-2">
                    <div class="flex w-full gap-2 font-bold">
                        <Label for="name" class="mb-0 text-right ">Price</Label>
                        <QuestionMark href="#" tooltipText="Set post price" />
                    </div>
                    <div class="relative w-full">
                        <Input
                            id="price"
                            type="number"
                            class="w-full appearance-none rounded-md pr-10 outline-none focus:outline-none [&::-webkit-inner-spin-button]:appearance-none"
                            placeholder="Enter price"
                            bind:value="{modalPrice}"
                        />

                        <!-- Light Mode Image -->
                        <img
                            src="/svg/points_light.svg"
                            alt="Points icon light"
                            class="pointer-events-none absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2 object-contain dark:hidden"
                        />
                        <!-- Dark Mode Image -->
                        <img
                            src="/svg/points_dark.svg"
                            alt="Points icon dark"
                            class="pointer-events-none absolute right-2 top-1/2 hidden h-6 w-6 -translate-y-1/2 object-contain dark:block"
                        />
                    </div>
                    <p class="text-semibold mt-[-7px]">~ {dollarPrice} USD</p>

                    <div>
                        <div class="flex gap-2">
                            <Label for="name" class="mb-0 text-right">Downloads</Label>
                            <QuestionMark href="#" tooltipText="Set who downloads post" />
                        </div>

                        <Tabs bind:value="{modalSelectedTab}" class="mt-2 w-full">
                            <TabsList
                                class="m-0 flex w-full justify-between rounded-md bg-gray-100 p-0 font-sans font-bold dark:bg-[#151515]"
                            >
                                <TabsTrigger
                                    value="Nobody"
                                    class="rounded-md px-4 py-2 transition-colors data-[state=active]:bg-white  data-[state=active]:text-black data-[state=active]:shadow-md"
                                >
                                    Nobody
                                </TabsTrigger>
                                <TabsTrigger
                                    value="Owners"
                                    class="rounded-md px-4 py-2 transition-colors data-[state=active]:bg-white data-[state=active]:text-black data-[state=active]:shadow-md"
                                >
                                    Owners
                                </TabsTrigger>
                                <TabsTrigger
                                    value="Everybody"
                                    class="rounded-md px-4 py-2 transition-colors data-[state=active]:bg-white data-[state=active]:text-black data-[state=active]:shadow-md"
                                >
                                    Everybody
                                </TabsTrigger>
                            </TabsList>
                        </Tabs>
                    </div>

                    <div class="mt-2 flex w-full gap-3">
                        <Label for="name" class="pt-1 text-right">Limited Edition</Label>
                        <QuestionMark href="#" tooltipText="Set post as limited edition" />
                        <Switch id="limited-edition-switch" bind:checked="{modalIsLimitedEdition}" />
                    </div>
                    <div class="mt-2 w-full">
                        <Label for="name" class="text-right  ">Supply Limit</Label>

                        <Input
                            id="supply-limit"
                            type="number"
                            class="appearance-none rounded-md focus:outline-none [&::-webkit-inner-spin-button]:appearance-none"
                            bind:value="{modalSupplyLimit}"
                        />
                    </div>

                    <div class="w-full">
                        <Label for="name" class="text-right">Title</Label>

                        <Input
                            id="title"
                            type="text"
                            class=" rounded-md focus:outline-none"
                            bind:value="{$postTitle}"
                        />
                    </div>
                    <div class="mt-2 w-full">
                        <Label for="name" class="text-right">Sales Mode</Label>

                        <span class="flex items-center gap-2">
                            <ion-icon name="cart-outline" class="text-3xl"></ion-icon>
                            <Label for="name" class="m-0 text-right">Listing</Label>
                        </span>
                    </div>
                </div>
                <Dialog.Footer>
                    <Button class="w-full" type="submit" on:click="{saveChanges}">Apply</Button>
                </Dialog.Footer>
            </Dialog.Content>
        </Dialog.Root>

        <div class="flex items-center gap-6">
            <div class="flex gap-2 text-sm font-bold">
                <span>Price</span>
            </div>

            <span class="rounded bg-[#D9D9D90D] px-2 py-1 text-sm">
                {#if $price !== null}
                    {$price}
                {:else}
                    Free
                {/if}
            </span>
        </div>

        <span class="text-sm font-bold text-zinc-700" class:line-through="{!$isLimitedEdition}"> Limited Edition </span>

        <div class="mt-0 flex items-center gap-6">
            <span class="text-sm font-bold">Downloads</span>
            <Button variant="secondary" class="rounded bg-[#D9D9D90D] px-3 py-1 text-sm">{$selectedTab}</Button>
        </div>
    </div>

    <div class="mt-2 flex">
        <Button
            on:click="{() => isPublishDialogOpen.set(true)}"
            class="mt-3 w-full rounded-md bg-indigo-600 px-6 py-2 font-semibold text-white transition hover:bg-indigo-700 sm:w-[150px]"
        >
            {isEditMode ? 'Update' : 'Publish'}
        </Button>
    </div>
</div>
<!-- Confirmation Dialog -->
<Dialog.Root bind:open="{$isPublishDialogOpen}">
    <Dialog.Content class="max-w-md rounded-md p-6 shadow-lg ">
        <Dialog.Header>
            <Dialog.Title class="text-lg font-semibold">Confirm Publish</Dialog.Title>
            <Dialog.Description class="text-md">
                Are you sure you want to publish this post? Once published, it will be visible to others.
            </Dialog.Description>
        </Dialog.Header>
        <div class="mt-4 flex justify-end gap-4">
            <Dialog.Close asChild>
                <Button variant="secondary" class="px-4 py-2" on:click="{handlePublishCancel}">Cancel</Button>
            </Dialog.Close>
            <Button
                class="rounded-md bg-indigo-600 px-4 py-2 text-white transition hover:bg-indigo-700"
                on:click="{handlePublishConfirm}"
            >
                Confirm
            </Button>
        </div>
    </Dialog.Content>
</Dialog.Root>
